
import request from '@/utils/request'

// 獲取列表
export function getListRequest(params) {
  return request({
    url: '/admin/Sys_Log/getList',
    method: 'get',
    params
  })
}
