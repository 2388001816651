<template>
  <div class="ad-list">
    <!-- 頁頭 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首頁</el-breadcrumb-item>
            <el-breadcrumb-item>日志</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container"></el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="模块">
        <el-input v-model="name" placeholder="請輸入"></el-input>
      </el-form-item>
      <el-form-item label="操作人">
        <el-input v-model="operateUser" placeholder="請輸入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查詢</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要內容 -->
    <div class="main">
      <el-table :data="adminList" border="" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center" width="95"></el-table-column>
        <el-table-column prop="moduleCN" label="模块"></el-table-column>

        <el-table-column prop="operateType" label="动作"></el-table-column>
        <el-table-column prop="ip" label="IP"></el-table-column>
        <el-table-column prop="operateUser" label="操作人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <!-- <el-table-column prop="method" label="方法"></el-table-column>
        <el-table-column prop="address" label="路由"></el-table-column> -->

        <!-- <el-table-column prop="message" label="參數"></el-table-column> -->
      </el-table>
    </div>
    <!-- 分頁 -->
    <div class="pagination">
      <el-pagination background="" layout="total,prev, pager, next" :page-size="15" :current-page="pageIndex"
        :total="pageTotal" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import {
  getListRequest,
  setStatesRequest,
  delRequest,
  setSortRequest
} from '@/api/syslog'
export default {
  name: 'advlist',
  data() {
    return {
      tabHeight: window.innerHeight - 320,
      name: '',
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
      operateUser: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getListRequest({
        name: this.name,
        operateUser: this.operateUser,
        pageNum: this.pageIndex,
        pageSize: 15
      }).then(res => {
        let data = res.data
        this.adminList = data.data
        this.pageTotal = data.count
      })
    },

    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder
      }).then(res => { })
    },
    setStates(id, states, row) {
      this.$confirm('此操作將改變狀態, 是否繼續?', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setStatesRequest({
          Id: id,
          States: states
        }).then(res => {
          row.states = states
        })
      })
    },
    toCreate() {
      this.$router.push({
        path: '/sys_role/edit',
        query: {
          id: 0
        }
      })
    },
    toEdit(id) {
      this.$router.push({
        path: '/sys_role/edit',
        query: {
          id: id
        }
      })
    },
    topower(id) {
      this.$router.push({
        path: '/sys_role/rolepower',
        query: {
          id: id
        }
      })
    },

    onDelete(id) {
      this.$confirm('此操作將刪除改行, 是否繼續?', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRequest({ Id: id }).then(res => {
          if (res.code == 1) {
            this.$message({
              type: 'success',
              message: `成功`
            })
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
